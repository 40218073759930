import {useMemo} from 'react';
import {useMendel} from '@/modules/ABTesting';
import {experiments} from '@/modules/ABTesting/mendel/config/Experiments.definition';
import {useHomeVisited} from './useHomeVisited';

const {GRW_NICA_ON_HP} = experiments;

export interface NicaOnHPResult {
  isLoading: boolean;
  isEnabled: boolean;
  srcHowItWorks?: {experiment: string; variation: string};
  srcIntroVideo?: {folder: string};
  srcIntroVideoFallback?: {experiment: string; variation: string};
}

export const useNicaOnHP = (): NicaOnHPResult => {
  const isHomeVisited = useHomeVisited();

  const {isLoading, variant} = useMendel({name: GRW_NICA_ON_HP.name, enabled: isHomeVisited});

  const isEnabled = variant === GRW_NICA_ON_HP.variants.v1;

  return useMemo(() => {
    if (!isEnabled) return {isLoading, isEnabled};

    return {
      isLoading,
      isEnabled,
      srcHowItWorks: {experiment: 'rebrand', variation: 'grw_NicaOnHP'},
      srcIntroVideo: {folder: 'grw_NicaOnHP'},
      srcIntroVideoFallback: {experiment: 'rebrand', variation: 'grw_NicaOnHP'},
    };
  }, [isLoading, isEnabled]);
};
