import {useMemo} from 'react';

import {useFreeTrialOut} from './useFreeTrialOut';
import {useFreeTrialOutAftermath} from './useFreeTrialOutAftermath';

export interface FreeTrialResult {
  isLoading: boolean;
  isExpEnabled: boolean;
  hasFreeTrial: boolean;
  text?: {
    cta: string;
    aboutCost: string;
    howItWorks: string;
  };
}

export const useFreeTrial = (): FreeTrialResult => {
  const freeTrialOutResult = useFreeTrialOut({enabled: true});
  const freeTrialAftermathResult = useFreeTrialOutAftermath({enabled: true});

  const isLoading = freeTrialOutResult.isLoading || freeTrialAftermathResult.isLoading;
  const isExpEnabled = freeTrialOutResult.isEnabled || freeTrialAftermathResult.isEnabled;
  const hasFreeTrial = !freeTrialOutResult.isEnabled || freeTrialAftermathResult.isEnabled;
  const text = freeTrialOutResult.text || freeTrialAftermathResult.text;

  return useMemo(() => ({isLoading, isExpEnabled, hasFreeTrial, text}), [isLoading, isExpEnabled, hasFreeTrial, text]);
};
