import {useEffect, useState} from 'react';
import {isHomeRoute, useLocationEvent} from '@/routes';

export const useHomeVisited = (): boolean => {
  const {pathname} = useLocationEvent();

  const [isVisited, setIsVisited] = useState(isHomeRoute(pathname));

  useEffect(() => {
    if (isHomeRoute(pathname)) setIsVisited(true);
  }, [pathname]);

  return isVisited;
};
