import {TinyEmitter} from 'tiny-emitter';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventCallback = (payload: any) => void;

const eventEmitter = new TinyEmitter();

export enum UI_EVENT {
  PAGE_VIEW = 'page_view',
  QUIZ = 'quiz',
  EXPERIMENT_APPLIED = 'experiment_applied',
  STYLE_CURATOR_SLIDER = 'style_curator_slider',
  FAQ_ACCORDION_EXPANDED = 'faq_accordion_expanded',
  Q1_OPTION_SELECT = 'q1_option_select',
  PAIN_SLIDER = 'pain_slider',
  ROUTER = 'router',
}

export const eventManager = {
  emit: (event: UI_EVENT, payload?: unknown): void => {
    eventEmitter.emit(event, payload);
  },
  subscribe: (event: UI_EVENT, callback: EventCallback): void => {
    eventEmitter.on(event, callback);
  },
  unsubscribe: (event: UI_EVENT, callback: EventCallback): void => {
    eventEmitter.off(event, callback);
  },
};
