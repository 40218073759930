import {useEffect, useState} from 'react';
import {eventManager, UI_EVENT} from '@/modules/EventManager';

interface Location {
  pathname: string;
}

export const useLocationEvent = (): Location => {
  const [location, setLocation] = useState<Location>({
    pathname: window.location.pathname,
  });

  useEffect(() => {
    const callback = (payload: Location) => setLocation(payload);
    eventManager.subscribe(UI_EVENT.ROUTER, callback);
    return () => eventManager.unsubscribe(UI_EVENT.ROUTER, callback);
  }, []);

  return location;
};
