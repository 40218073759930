import {emitUserEvent, initTracking} from '@lookiero/user-tracking-front';
import {UI_EVENT, eventManager} from '@/modules/EventManager';
import {getSiteConfig, hasSite} from '@/modules/Site';
import {SECTION, USER_TRACKING_EVENT_MAP} from './tracking.definition';
import {Site} from '@/types/Site';

const {userTracker} = initTracking({});
const {site} = getSiteConfig();
const store = hasSite() ? site : undefined;

const subscribeNavigationEvents = () => {
  eventManager.subscribe(UI_EVENT.PAGE_VIEW, payload => {
    const eventId = USER_TRACKING_EVENT_MAP[UI_EVENT.PAGE_VIEW];
    const eventData = {event: eventId, eventCategory: 'navigation', section: '', store, ...payload};
    userTracker(eventData);
  });

  eventManager.subscribe(UI_EVENT.STYLE_CURATOR_SLIDER, payload => {
    const eventId = USER_TRACKING_EVENT_MAP[UI_EVENT.STYLE_CURATOR_SLIDER];
    const eventData = {event: eventId, eventCategory: 'navigation', section: SECTION.HOME, store, ...payload};
    emitUserEvent(eventData);
  });

  eventManager.subscribe(UI_EVENT.PAIN_SLIDER, payload => {
    const eventId = USER_TRACKING_EVENT_MAP[UI_EVENT.PAIN_SLIDER];
    const eventData = {event: eventId, eventCategory: 'navigation', section: SECTION.HOME, store, ...payload};
    emitUserEvent(eventData);
  });

  eventManager.subscribe(UI_EVENT.FAQ_ACCORDION_EXPANDED, payload => {
    emitUserEvent({
      event: `home_faqs_${payload.id}`,
      eventCategory: 'navigation',
      store: store as Site,
      section: SECTION.HOME,
    });
  });

  eventManager.subscribe(UI_EVENT.Q1_OPTION_SELECT, payload => {
    const eventData = {eventCategory: 'navigation', section: SECTION.HOME, store, ...payload};
    emitUserEvent(eventData);
  });
};

const subscribeExperimentEvents = () => {
  eventManager.subscribe(UI_EVENT.EXPERIMENT_APPLIED, payload => {
    const eventId = USER_TRACKING_EVENT_MAP[UI_EVENT.EXPERIMENT_APPLIED];
    const eventData = {event: eventId, eventCategory: 'experiment', ...payload};
    emitUserEvent(eventData);
  });
};

export const setupTracking = () => {
  subscribeNavigationEvents();
  subscribeExperimentEvents();
};
